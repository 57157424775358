<template>
  <div class="explanation-page">
    <div class="header">
      <router-link class="index-logo" to="/">
        <img class="logo" src="@/assets/images/logo.png" />
      </router-link>
    </div>
    <div class="content-box">
      <div class="content">
        <h2>About Kizgamer</h2>
        <h3>Kizgamer.com is a cartoon game website designed for kids to have fun while learning. We are dedicated to
          offering kids games that can bring them fun and help them learn. By now, there are hundreds of interesting
          games with all themes loved by kids from cute animals to fancy cars. Our games are not only entertaining but
          also educational, allowing kids to acquire knowledge from different fields while playing.</h3>
        <h3>Our games are especially appealing to kids with their cute characters, simple rules and colorful pictures.
          Playing our games, kids can learn different colors, animals, occupations, etc. Our games are free and
          available on cellphone, tablet and laptop. Enjoy yourself on Kizgamer.com!</h3>
        <h3>If you have any sugestions, please don't hesitate and send us an email at support@kizgamer.com.</h3>
        <Tips />
      </div>
    </div>
  </div>
</template>

<script>
import Tips from "@/components/Tips.vue";
export default {
  components: {  Tips },

}
</script>
<style lang="scss">
@import "~@/assets/scss/explanation";
</style>